import { Component, OnInit, ViewChild } from "@angular/core";
import { SupportService } from "../services/support.service";
import { Support } from "../models/support";
import { MessageHandlerService } from "../services/message-handler.service";
import { dateAndTimeFormat, dateFormat, rowsPerPageOptions } from "../common/Constants";
import { Paginator } from "primeng/paginator";
import { ProgressBarService } from "../services/progress-bar.service";
import { RoleEnum } from "src/app/models/agency";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"],
})
export class SupportComponent implements OnInit {
  support: Support[] = [];
  selectedSupport: Support[] = [];
  selectedIndex: number;
  display: boolean = false;
  pageNo: number = 0;
  noOfRecordPerRow: number = 30;
  totalRecords: number = 0;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  @ViewChild("paginator") paginator: Paginator;
  RoleEnum = RoleEnum;
  dateFormat = dateFormat;
  dateAndTimeFormat = dateAndTimeFormat;

  constructor(
    private supportService: SupportService,
    private messageHandlerService: MessageHandlerService,
    private progressService: ProgressBarService,
  ) {}

  ngOnInit(): void {
    this.getSupportData();
  }

  getSupportData() {
    try {
      let request = {
        pagination: {
          pageNumber: this.pageNo,
          pageSize: this.noOfRecordPerRow,
        },
        search: "",
      };
      this.progressService.setProgressBarVisibility(true);
      this.supportService.getSupportData(request).subscribe((res) => {
        if (res.success && res.content && res.content.data) {
          this.support = res.content.data;
          this.totalRecords = res.content.totalElements;
          console.log(this.support);
        } else if (res) {
          this.messageHandlerService.generateToastMessage("error", "", "Unable to fetch support data");
        }
        this.progressService.setProgressBarVisibility(false);
      });
    } catch (error) {
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
  }

  onRowSelect(index) {
    this.selectedIndex = index;
    this.display = true;
  }
  toggleStatus(supportElement: Support): void {
    const updateSupport = { ...supportElement };
    if (updateSupport.ticketStatus) {
      updateSupport.ticketStatus = updateSupport.ticketStatus === "Resolved" ? "Unresolved" : "Resolved";

      this.supportService.updateSupport(updateSupport).subscribe((res) => {
        if (res.success == false) {
          this.messageHandlerService.generateToastMessage("error", "", "Unable to update support ticket");
        } else {
          this.getSupportData();
        }
      });
    }
  }
  paginate(event) {
    if (!this.isChangingPage) {
      this.pageNo = event.page;
      this.noOfRecordPerRow = event.rows;
      this.getSupportData();
    }
  }
  changePage() {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo);
      this.isChangingPage = false;
    });
  }
}
