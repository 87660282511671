import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CompanyService } from "../services/company/company.service";
import { Company, companyStatusValues, statusValues, status, defaultSelectedCompanyRows, CompanyyListingRows } from "../models/company";
import { MessageHandlerService } from "src/app/services/message-handler.service";
import { UserDataManager } from "src/app/user/UserDataManager";
import { User } from "src/app/models/user";
import { Table } from "primeng/table";
import { ConfirmationService, SelectItem } from "primeng/api";
import { ProgressBarService } from "src/app/services/progress-bar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { countries } from "../models/country-data";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { FileUpload } from "primeng/fileupload";
import {
  UTILIZATION_REPORT_FILE_NAME,
  USER_DEVICES_REPORT_FILE_NAME,
  DATE_PICKER_FORMAT,
  DATE_PICKER_PLACEHOLDER,
  dateFormat,
  dateFormatStartWithYear,
  dateFormatStartWithYearWithOutDashes,
  DEFAULT_SEARCH_MIN_CHARACTER,
  EMAIL_ALREADY_REGISTERED_COMPANY,
  EXCEL_EXTENSION,
  EXCEL_TYPE,
  HideMrnForUsers,
  RoleID,
  rowsPerPageOptions,
  AGENCY_PATH,
  NO_OF_RECORDS_PER_PAGE,
  REPORT_TIME_ZONE
} from "../common/Constants";
import { Paginator } from "primeng/paginator";
import { SidenavService } from "../core/sidebar/sidenav.service";
import { HttpParams } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { UtilService } from "../services/util/util.service";
import { saveAs } from "file-saver";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { AgencyService } from "../services/agency/agency.service";
import { select, Store } from "@ngrx/store";
import { AppState } from "../store/reducers";
import { addAgency, removeAllAgencies } from "../store/agency.actions";
import { addCompany } from "../store/company.actions";
import { LocalStorageService } from "../services/local-storage.service";
import { Router } from "@angular/router";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "app-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.scss"],
})
export class CompaniesComponent implements OnInit, OnDestroy {
  companies: Company[] = [];
  display = false;
  addCompanyForm: FormGroup;
  currentUser: User;
  selectedCompanies: Company[] = [];
  exportReportValues = [
    { label: "Utilization data", icon: "pi pi-download", value: "Utilization data" },
    // { label: 'Utilization data', icon: 'pi pi-download', command: () => this.onExportData() },
    { label: "Users & Devices", icon: "pi pi-download", value: "Users & Devices" },
  ];
  rangeDates: Date[];
  dateRangeToExportAgencyData;
  datePickerFormat = DATE_PICKER_FORMAT;
  datePickerPlaceholder = DATE_PICKER_PLACEHOLDER;
  searchCompany: string = "";
  totalCompanies: number = 0;
  companyToUpdate: Company;
  currentImage: any;
  statusEnum = status;
  showEditForm: boolean = false;
  selectedIndex: any;
  subscriptions: Subscription[] = [];
  currentAgencyRole: { agency: string; role: string };
  pageNo: number = 0;
  noOfCompaniesPerRow: number = 30;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  companyAdminUser = RoleID.companyAdmin;
  iohSuperAdmin = RoleID.Admin;
  iohAdmin = RoleID.iohAdmin;
  agencyAdmin = RoleID.AgencyAdmin;
  iohAgencyAdmin = RoleID.IOHAgencyAdmin;
  countries = countries;
  companyStatusFilterOptions: SelectItem[] = companyStatusValues;
  attachedAgencies = [];
  selectedCompanyStatus;
  selectedAgencyLevel: SelectItem;
  companyStatusFilter: string = "";
  componentLocalStorageKey = "companyState";
  @ViewChild("paginator") paginator: Paginator;
  @ViewChild("fileUpload", { static: false }) fileUpload: FileUpload;
  @ViewChild("dt") table: Table;
  @ViewChild("reportDropdown") reportDropdown: Dropdown;
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;
  protected readonly statusValues = statusValues;
  companies$: Observable<any>;
  selectedRows: any[] = defaultSelectedCompanyRows;
  visibleRows: any[] = CompanyyListingRows;
  selectedCompaniesStore = [];

  constructor(
    private companyService: CompanyService,
    private messageHandlerService: MessageHandlerService,
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sideNavService: SidenavService,
    private datePipe: DatePipe,
    private agencyService: AgencyService,
    private utilService: UtilService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
    this.matIconRegistry.addSvgIcon("status", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/active-inactive.svg"));
    this.companies$ = this.store.pipe(select((state) => state.companyState.companies));
  }

  ngOnInit(): void {
    this.getUserProfile();
    this.getDataFromLocalStorage();
    this.getCompanies();
    this.createCompanyForm();
    this.getSelectedRows();
  }

  getDataFromLocalStorage() {
    const companyState = JSON.parse(this.localStorageService.get(this.componentLocalStorageKey));
    this.selectedCompanyStatus = companyState?.selectedCompanyStatus ? companyState?.selectedCompanyStatus : this.companyStatusFilterOptions[1].value;
    this.companyStatusFilter = this.selectedCompanyStatus;
  }

  getCompanyValuesFromStore() {
    this.subscriptions.push(
      this.companies$.subscribe((storeCompanies) => {
        this.selectedCompanies = [];
        this.selectedCompaniesStore = [];
        if (storeCompanies.length) {
          storeCompanies.forEach((storeCompany) => {
            const selectedCompanyMatch = this.companies.find((company) => company.id == storeCompany);
            this.selectedCompaniesStore.push(storeCompany);
            if (selectedCompanyMatch) {
              this.selectedCompanies.push(selectedCompanyMatch);
            }
          });
        }
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  onRowSelect() {
    const newValue: string[] = this.selectedCompanies.map((company) => {
      return company?.id;
    });
    this.selectedCompaniesStore = [...new Set(newValue.concat(this.selectedCompaniesStore))];
    this.store.dispatch(addCompany({ companies: this.selectedCompaniesStore }));
    this.store.dispatch(removeAllAgencies());
    this.resetFilters();
  }

  onRowUnSelect(event) {
    const index = this.selectedCompaniesStore.indexOf(event?.data?.id);

    if (index !== -1) {
      this.selectedCompaniesStore.splice(index, 1);
    }

    this.store.dispatch(addCompany({ companies: this.selectedCompaniesStore }));
    this.store.dispatch(removeAllAgencies());
    this.resetFilters();
  }

  onHeaderCheckboxToggle(event) {
    const companiesIds = this.companies.map((company) => company?.id);
    if (event?.checked) {
      this.selectedCompaniesStore = [...new Set(this.selectedCompaniesStore.concat(companiesIds))];
    } else {
      companiesIds.forEach((companyId) => {
        const index = this.selectedCompaniesStore.indexOf(companyId);

        if (index !== -1) {
          this.selectedCompaniesStore.splice(index, 1);
        }
      });
    }

    this.store.dispatch(addCompany({ companies: this.selectedCompaniesStore }));
    this.store.dispatch(removeAllAgencies());
    this.resetFilters();
  }

  resetFilters() {
    localStorage.removeItem("patientState");
    localStorage.removeItem("visitState");
    localStorage.removeItem("agencyState");
  }

  createCompanyForm() {
    this.addCompanyForm = this.formBuilder.group({
      logo: [null, Validators.required],
      name: ["", Validators.required],
      phoneNumber: this.formBuilder.group({
        number: [""],
        countryCode: [countries[0].dial_code],
      }),
      fax: this.formBuilder.group({
        number: [""],
        countryCode: [countries[0].dial_code],
      }),
      email: ["", [Validators.required, Validators.email]],
      status: ["Active"],
      address: this.formBuilder.group({
        state: ["", Validators.required],
        city: ["", Validators.required],
        line1: ["", Validators.required],
        line2: [""],
        postalCode: ["", Validators.required],
      }),
    });
  }

  onExportData() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedCompanies);
    let requestPayload = {
      companyIds: agenciesToExportIds,
      searchByDate: this.dateRangeToExportAgencyData,
    };
    this.getCompanyDataToExport(requestPayload);
  }

  onExportCompanyUtilizationData() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedCompanies);
    let requestPayload = {
      companyIds: agenciesToExportIds,
      searchByDate: this.dateRangeToExportAgencyData,
      reportType: "eDoc",
    };
    this.getCompanyUtilizationDataToExport(requestPayload);
  }

  onExportIohCompanyUsersInfo() {
    let agenciesToExportIds = this.utilService.getSelectedAgenciesIds(this.selectedCompanies);
    let requestPayload = {
      companyIds: agenciesToExportIds,
      searchByDate: this.dateRangeToExportAgencyData,
      IOH: false,
    };
    this.getIohCompanyUsersDataToExport(requestPayload);
  }

  getIohCompanyUsersDataToExport(requestPayload) {
    this.progressBarService.setProgressBarVisibility(true);
    this.companyService.getIohCompanyUsersReportToExport(requestPayload).subscribe(
      (res) => {
        this.progressBarService.setProgressBarVisibility(false);
        if (res && res.content && res.content.length) {
          let dataToExport = this.generateExcelReportDataForUserDevices(res.content);
          this.exportExcelReportForUsers(dataToExport);
        } else {
          this.messageHandlerService.generateToastMessage("error", "", `No data matching the search criteria is found to export`);
        }
      },
      (error) => {
        this.progressBarService.setProgressBarVisibility(false);
        this.messageHandlerService.generateToastMessage("error", "", `An error occurred ${error}`);
      },
    );
  }

  generateExcelReportDataForUserDevices(agencyData) {
    const exportData: any[] = [];
    if (agencyData) {
      for (let e of agencyData) {
        let exportRow = [
          e?.company && e?.company?.length
            ? e?.company[0].name[0]
            : e?.AgencyCompany && e?.AgencyCompany?.length
              ? e?.AgencyCompany[0].name[0]
              : ("" ?? ""),
          e?.agency_roles && e?.agency_roles.length ? (e?.agency_roles[0]?.agency?.agencyName ?? "") : "",
          e?.firstName ?? "",
          e?.lastName ?? "",
          e?.status ?? "",
          e?.email ?? "",
          e?.is_company_admin ? this.companyAdminUser : (e?.defaultRole ?? ""),
          e?.androidVersion ?? "",
          e?.desktopVersion ?? "",
          e?.platform ?? "",
          e?.lastLogin ? this.utilService.toLocal(e?.lastLogin, REPORT_TIME_ZONE) : "" ?? "",
          e?.lastActivity ? this.utilService.toLocal(e?.lastActivity, REPORT_TIME_ZONE) : "" ?? "",
          e?.lastAction ? this.utilService.toLocal(e?.lastAction, REPORT_TIME_ZONE) : "" ?? "",
          e?.userSettings?.isMultiAgenciesAllowed ?? "",
          e?.createdByUsers[0] ? (e?.createdByUsers[0]?.firstName[0] + " " + e?.createdByUsers[0]?.lastName[0] ?? "") : "",
          e?.createdAt ? this.utilService.toLocal(e?.createdAt, REPORT_TIME_ZONE) : "" ?? "",
          e?.userSettings?.logging ?? "",
          e?.userSettings?.loggingView ?? "",
          e?.permissions && e?.permissions.length ? e?.permissions.join(", ") : "",
        ];
        exportData.push(exportRow);
      }
    }
    return exportData;
  }

  exportExcelReportForUsers(dataToExport) {
    // creating workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("User Devices");

    //adding filter dates row
    this.addFilterDatesRow(worksheet, "user");

    //adding header row
    this.addHeaderRowForUsers(worksheet);

    //adding data row
    worksheet.addRows(dataToExport);

    //writing data to excel file
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(
        blob,
        (this.selectedCompanies.length === 1 ? `${this.selectedCompanies[0].name}-${USER_DEVICES_REPORT_FILE_NAME}` : USER_DEVICES_REPORT_FILE_NAME) +
          this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) +
          EXCEL_EXTENSION,
      );
    });
  }

  addHeaderRowForUsers(worksheet) {
    const worksheetHeaders = this.getExcelReportHeadersForUsers();
    const headerRowValues = worksheetHeaders.map((obj) => obj.key);
    let headersRow = worksheet.addRow(headerRowValues);

    headersRow.eachCell((row, colNumber) => {
      row.style = {
        font: { bold: true },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FFFF00" },
        },
      };
    });

    worksheetHeaders.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
      column.width = header.width; // Set the width from the response
      column.alignment = { vertical: "middle", horizontal: "left" };
    });
  }

  addHeaderRowForUtilization(worksheet) {
    const worksheetHeaders = this.getExcelReportHeadersForUtilization();
    const headerRowValues = worksheetHeaders.map((obj) => obj.key);
    let headersRow = worksheet.addRow(headerRowValues);

    headersRow.eachCell((row, colNumber) => {
      row.style = {
        font: { bold: true },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
          bgColor: { argb: "FFFF00" },
        },
      };
    });

    worksheetHeaders.forEach((header, index) => {
      const column = worksheet.getColumn(index + 1); // Columns are 1-indexed
      column.width = header.width; // Set the width from the response
      column.alignment = { vertical: "middle", horizontal: "left" };
    });
  }

  getExcelReportHeadersForUtilization() {
    return [
      { header: "Agency", key: "Agency", width: 20 },
      { header: "io Health ID", key: "io Health ID", width: 35 },
      { header: "00 validation", key: "00 validation", width: 30 },
      { header: "EDOC", key: "EDOC", width: 30 },
      { header: "18 submitted", key: "18 submitted", width: 20 },
    ];
  }

  getExcelReportHeadersForUsers() {
    return [
      { header: "Company", key: "Company", width: 20 },
      { header: "Agency", key: "Agency", width: 20 },
      { header: "FirstName", key: "FirstName", width: 30 },
      { header: "LastName", key: "LastName", width: 30 },
      { header: "Status", key: "Status", width: 20 },
      { header: "Email", key: "Email", width: 50 },
      { header: "DefaultRole", key: "DefaultRole", width: 30 },
      { header: "Android Version", key: "Android Version", width: 20 },
      { header: "Desktop Version", key: "Desktop Version", width: 20 },
      { header: "Platform", key: "Platform", width: 20 },
      { header: "Last Login", key: "Last Login", width: 30 },
      { header: "Last Activity", key: "Last Activity", width: 30 },
      { header: "Last Action", key: "Last Action", width: 30 },
      { header: "Multi Agencies Allowed", key: "Multi Agencies Allowed", width: 20 },
      { header: "Created By", key: "Created By", width: 50 },
      { header: "Created At", key: "Created At", width: 30 },
      { header: "Logging", key: "Logging", width: 20 },
      { header: "Logging View", key: "Logging View", width: 20 },
      { header: "Permissions", key: "Permissions", width: 20 },
    ];
  }

  addFilterDatesRow(worksheet, type: "user" | "utilize") {
    let dateRangeString = "";
    let titleString = "";
    let filterRow;
    if (this.dateRangeToExportAgencyData?.startDate && !this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (this.dateRangeToExportAgencyData?.startDate && this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear); //
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (!this.dateRangeToExportAgencyData?.startDate && !this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ---- - ${startDate}`;
    }

    if (this.selectedCompanies && this.selectedCompanies.length === 1) {
      if (type === "user") {
        titleString = `Report: ${this.selectedCompanies[0].name} - ${USER_DEVICES_REPORT_FILE_NAME}`;
      } else if (type === "utilize") {
        titleString = `Report: ${this.selectedCompanies[0].name} - ${UTILIZATION_REPORT_FILE_NAME}`;
      }
    } else if (this.selectedCompanies && this.selectedCompanies.length > 1) {
      if (type === "user") {
        titleString = `Report: ${USER_DEVICES_REPORT_FILE_NAME}`;
      } else if (type === "utilize") {
        titleString = `Report: ${UTILIZATION_REPORT_FILE_NAME}`;
      }
    }

    filterRow = worksheet.addRow([titleString]);
    worksheet.mergeCells(`A${filterRow.number}:Z${filterRow.number}`);
    filterRow = worksheet.addRow([dateRangeString]);
    worksheet.mergeCells(`A${filterRow.number}:Z${filterRow.number}`);
    worksheet.addRow([]);
  }

  getCompanyDataToExport(requestPayload) {
    this.progressBarService.setProgressBarVisibility(true);
    this.companyService.getCompanyReportsExport(requestPayload).subscribe(
      (res) => {
        this.progressBarService.setProgressBarVisibility(false);
        if (res) {
          let dataToExport = this.generateCompanyUtilizationStats(res);
          this.exportCompanyUtilizationStats(dataToExport);
        } else {
          this.messageHandlerService.generateToastMessage("error", "", `An error occurred`);
        }
      },
      (error) => {
        this.progressBarService.setProgressBarVisibility(false);
        this.messageHandlerService.generateToastMessage("error", "", `An error occurred ${error}`);
      },
    );
  }

  getCompanyUtilizationDataToExport(requestPayload) {
    this.progressBarService.setProgressBarVisibility(true);
    this.companyService.getCompanyUtilizationReportData(requestPayload).subscribe(
      (res) => {
        this.progressBarService.setProgressBarVisibility(false);
        if (res) {
          let dataToExport = this.generateCompanyUtilizationData(res);
          this.exportCompanyUtilizationStats(dataToExport);
        } else {
          this.messageHandlerService.generateToastMessage("error", "", `An error occurred`);
        }
      },
      (error) => {
        this.progressBarService.setProgressBarVisibility(false);
        this.messageHandlerService.generateToastMessage("error", "", `An error occurred ${error}`);
      },
    );
  }

  exportExcelReport(formattedData) {
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(formattedData[0]);
    let csv = formattedData.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));
    csv.unshift(header.join(","));
    let csvArray = csv.join("\r\n");

    let blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "Utilization Report-" + this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) + ".csv");
  }

  generateCompanyUtilizationStats(companyData) {
    const exportData: any[] = [];

    if (companyData && companyData.length) {
      companyData.forEach((company, index) => {
        if (index == 0) {
          exportData.push({
            From: "From",
            Date: this.rangeDates && this.rangeDates.length ? this.datePipe.transform(this.rangeDates[0], dateFormat) : "",
          });
          exportData.push({
            To: "To",
            Date:
              this.rangeDates && this.rangeDates.length > 0
                ? this.datePipe.transform(this.rangeDates[1], dateFormat)
                : this.datePipe.transform(new Date(), dateFormat),
          });
        }
        exportData.push({});
        exportData.push({
          Company: "Report: ",
          Name: company?.companyName + " - " + UTILIZATION_REPORT_FILE_NAME,
        });

        exportData.push({});

        if (company?.agenciesData.length) {
          exportData.push({
            Agency: "Agency",
            "Total Patients": "Total Patients",
            "Total Patients with IO Utilization": "Total Patients with IO Utilization",
            "Total Errors": "Total Errors",
            "Total Warning": "Total Warning",
            "Total Esoc": "Total Esoc",
          });
          company.agenciesData.forEach((agency) => {
            exportData.push({
              Agency: agency?.agencyName,
              "Total Patients": agency?.totalPatients,
              "Total Patients with IO Utilization": agency?.iohPatients,
              "Total Errors": agency?.totalErrorCountForAgency,
              "Total Warning": agency?.totalWarningCountForAgency,
              "Total Esoc": agency?.totalPatientsWithESocSent,
            });
          });
        } else {
          exportData.push({});
        }
      });
    }

    return exportData;
  }

  generateCompanyUtilizationData(companyData) {
    const exportData: any[] = [];
    if (companyData && companyData.length) {
      for (let e of companyData) {
        e?.agencies?.forEach((agency) => {
          if (agency?.diagnosticGroups && agency?.diagnosticGroups?.length) {
            agency?.diagnosticGroups?.forEach((obj) => {
              if (obj?.patients && obj?.patients?.length) {
                obj?.patients?.forEach((obj) => {
                  let exportRow = [
                    agency?.agencyName ? agency?.agencyName : "",
                    this.showMrnOrPatientIdInCompanyUtilizationReport(obj),
                    obj?.SOCDate ? this.utilService.toLocal(obj?.SOCDate, REPORT_TIME_ZONE) : "",
                    obj?.allMinDates ? this.utilService.toLocal(obj?.allMinDates, REPORT_TIME_ZONE) : "",
                    obj?.DCDate ? this.utilService.toLocal(obj?.DCDate, REPORT_TIME_ZONE) : "",
                  ];
                  exportData.push(exportRow);
                });
              }
            });
          } else {
            let exportRow = [agency?.agencyName];
            exportData.push(exportRow);
          }
        });
      }
    }
    return exportData;
  }

  prepareDateRangeRow() {
    let dateRangeString;
    if (this.dateRangeToExportAgencyData?.startDate && !this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (this.dateRangeToExportAgencyData?.startDate && this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
      const endDate = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear); //
      dateRangeString = `Date Range: ${startDate} - ${endDate}`;
    } else if (!this.dateRangeToExportAgencyData?.startDate && !this.dateRangeToExportAgencyData?.endDate) {
      const startDate = this.datePipe.transform(new Date().toISOString(), dateFormatStartWithYear);
      dateRangeString = `Date Range: ---- - ${startDate}`;
    }

    return dateRangeString;
  }

  showMrnOrPatientIdInCompanyUtilizationReport(patientData) {
    if (patientData) {
      if (HideMrnForUsers[this.currentUser.currentUserRole]) {
        return patientData?._id;
      } else {
        return patientData?.mrn;
      }
    }
  }

  exportCompanyUtilizationStats(dataToExport) {
    // creating workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Utilization");

    //adding filter dates row
    this.addFilterDatesRow(worksheet, "utilize");

    //adding header row
    this.addHeaderRowForUtilization(worksheet);

    //adding data row
    worksheet.addRows(dataToExport);

    //writing data to excel file
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(
        blob,
        (this.selectedCompanies.length === 1
          ? `${this.selectedCompanies[0].name}-${UTILIZATION_REPORT_FILE_NAME}`
          : UTILIZATION_REPORT_FILE_NAME + "-") +
          this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) +
          EXCEL_EXTENSION,
      );
    });
    // console.log(formattedData);
    // const replacer = (key, value) => (value === null ? '' : value);
    // const csv = formattedData.map((row) =>
    //   Object.values(row)
    //     .map((value) => JSON.stringify(value, replacer))
    //     .join(','),
    // );
    // const csvArray = csv.join('\r\n');

    // const blob = new Blob([csvArray], { type: 'text/csv' });
    // saveAs(
    //   blob,
    //   (this.selectedCompanies.length === 1
    //     ? `${this.selectedCompanies[0].name}-${UTILIZATION_REPORT_FILE_NAME}-`
    //     : UTILIZATION_REPORT_FILE_NAME + '-') +
    //     this.datePipe.transform(new Date(), dateFormatStartWithYearWithOutDashes) +
    //     '.csv',
    // );
  }

  generateExcelReportData(companyData) {
    const exportData: any[] = [];

    if (companyData && companyData.length) {
      companyData.forEach((company) => {
        let exportRow = {
          Organization: company?.companyName ? company?.companyName : "",
          Agency: "",
          "Patient Number": "",
          "SOC date": "",
          "Total # of visits from SOC to date of report": "",
          "Dx Group": "",
          "Discharge Date": "",
          Edoc: "",
        };
        if (!company.agencies || !company.agencies.length) exportData.push(exportRow);

        if (company.agencies && company.agencies.length) {
          company.agencies.forEach((agency) => {
            let exportRow = {
              Organization: company?.companyName ? company?.companyName : "",
              Agency: agency?.agencyName ? agency?.agencyName : "",
              "Patient Number": "",
              "SOC date": "",
              "Total # of visits from SOC to date of report": "",
              "Dx Group": "",
              "Discharge Date": "",
              Edoc: "",
            };
            if (!agency.diagnosticGroups || !agency.diagnosticGroups.length) exportData.push(exportRow);

            if (agency.diagnosticGroups && agency.diagnosticGroups.length) {
              agency.diagnosticGroups.forEach((diagnosticGroup) => {
                let exportRow = {
                  Organization: company?.companyName ? company?.companyName : "",
                  Agency: agency?.agencyName ? agency?.agencyName : "",
                  "Patient Number": "",
                  "SOC date": "",
                  "Total # of visits from SOC to date of report": "",
                  "Dx Group": diagnosticGroup?.group ? diagnosticGroup?.group : "",
                  "Discharge Date": "",
                  Edoc: "",
                };

                if (!diagnosticGroup.patients || !diagnosticGroup.patients.length) exportData.push(exportRow);

                if (diagnosticGroup.patients && diagnosticGroup.patients.length) {
                  diagnosticGroup.patients.forEach((patient) => {
                    let exportRow = {
                      Organization: company?.companyName ? company?.companyName : "",
                      Agency: agency?.agencyName ? agency?.agencyName : "",
                      "Patient Number": patient?._id ? patient?._id : "",
                      "SOC date": patient?.SOCDate ? this.datePipe.transform(patient?.SOCDate, dateFormat) : "",
                      "Total # of visits from SOC to date of report": patient?.totalVisits ? patient?.totalVisits : "",
                      "Dx Group": diagnosticGroup?.group ? diagnosticGroup?.group : "",
                      "Discharge Date": patient?.DCDate ? this.datePipe.transform(patient?.DCDate, dateFormat) : "",
                      Edoc: patient?.isEsoc ? patient?.isEsoc : "",
                    };
                    exportData.push(exportRow);
                  });
                }
              });
            }
          });
        }
      });
    }

    return exportData;
  }

  setDatesForExport() {
    let startDateFilter: any = this.datePipe.transform(this.rangeDates[0], dateFormatStartWithYear);
    let endDateFilter: any = this.datePipe.transform(this.rangeDates[1], dateFormatStartWithYear);

    this.dateRangeToExportAgencyData = {
      startDate: this.utilService.convertToISOString(startDateFilter, true),
      endDate: this.utilService.convertToISOString(endDateFilter, false),
    };
  }

  onCalendarClear() {
    this.rangeDates = null;
    this.dateRangeToExportAgencyData = null;
  }

  async onSelect(event) {
    const file = event.files[0];

    const formData = new FormData();
    formData.append("file", file);

    if (event.currentFiles && event.currentFiles.length > 0) {
      this.progressBarService.setProgressBarVisibility(true);
      await this.companyService.uploadFile(formData).subscribe((res) => {
        if (res?.message?.Location) {
          this.currentImage = res;
          this.addCompanyForm.get("logo").setValue(res.message.Location);
          this.fileUpload.clear();
        }
        this.progressBarService.setProgressBarVisibility(false);
      });
    }
  }

  async deleteFile() {
    await this.companyService
      .deleteFile({
        bucketName: "grandcare",
        objectKey: this.addCompanyForm.value.logo,
      })
      .subscribe((res) => {
        if (res) {
          this.currentImage = null;
          this.fileUpload.clear();
          this.addCompanyForm.patchValue({ logo: null });
        }
      });
  }

  getUserProfile() {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe((res) => {
        if (res) {
          this.currentUser = res;
        }
      }),
    );
  }

  // find companies with pagination and search
  getCompanies() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      let request = {
        companyId: this.getCompanyID(),
        ...(this.currentUser.currentUserRole === RoleID.Admin || this.currentUser.currentUserRole === RoleID.iohAdmin
          ? {
              search: this.searchCompany ? this.searchCompany : "",
              status: this.companyStatusFilter,
              pagination: { pageNumber: this.pageNo, pageSize: this.noOfCompaniesPerRow },
              //pagination: { pageNumber: this.companyStatusFilter === "" ? this.pageNo : 0, pageSize: this.noOfCompaniesPerRow },
            }
          : null),
      };
      this.subscriptions.push(
        this.companyService.getAllCompanies(request).subscribe((res) => {
          if (res?.success) {
            this.companies = res.content.data;
            this.totalCompanies = res.content.totalCompaniesCount;

            if (!this.isChangingPage) {
              this.changePage();
            }

            this.getCompanyValuesFromStore();
          } else if (res) {
            this.messageHandlerService.generateToastMessage("error", "Communication error:  ", res?.errorMessage);
          }
          this.progressBarService.setProgressBarVisibility(false);
        }),
      );
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage("error", "Communication error:  ", error);
    }
  }

  getCompanyID() {
    if (this.currentUser.currentUserRole === RoleID.companyAdmin) {
      return this.currentUser.companyId;
    }
    return null;
  }

  searchCompanies() {
    if (this.searchCompany === undefined || this.searchCompany === null || this.searchCompany === "") {
      this.getCompanies();
    } else if (this.searchCompany && this.searchCompany.length >= this.defaultSearchMinCharacter) {
      this.pageNo = 0;
      this.getCompanies();
    }
  }

  // Check if the company search field is empty
  isSearchEmpty() {
    if (this.searchCompany === undefined || this.searchCompany === null || this.searchCompany === "") {
      this.pageNo = 0;
      this.getCompanies();
    }
  }

  createCompany() {
    let newCompany = this.addCompanyForm.value;
    newCompany.createdBy = this.currentUser.aws_cognito_id;
    newCompany.createdAt = new Date();
    this.subscriptions.push(
      this.companyService.createCompanyy(newCompany).subscribe((res) => {
        if (res.success) {
          this.getCompanies();
          this.display = false;
          this.messageHandlerService.generateToastMessage("success", "", "Company Created Successfully");
          this.sideNavService.setEDocPurchase();
        } else {
          this.messageHandlerService.generateToastMessage("error", "", "Agency Not Created");
        }
      }),
    );
  }

  onSlideBarClose() {
    this.display = false;
    this.showEditForm = false;
    this.addCompanyForm.reset();
  }

  checkIfEmailExists() {
    const params = new HttpParams().append("email", this.addCompanyForm.value.email);
    this.subscriptions.push(
      this.companyService.findCompanyByEmail(params).subscribe((res) => {
        if (res.success && res.content.data) {
          this.messageHandlerService.generateToastMessage("error", "", EMAIL_ALREADY_REGISTERED_COMPANY);
        } else if (!res.success && !res.content.data) {
          this.createCompany();
        } else {
          this.messageHandlerService.generateToastMessage("error", "", res.errorMessage);
        }
      }),
    );
  }

  openEditCompanyForm(company) {
    this.selectedIndex = company;
    this.showEditForm = true;
    this.display = true;
    this.getAgencyListing();
    this.prepareEditCompanyForm();
    this.createEditCompanyForm();
  }

  getAgencyListing() {
    let params = new HttpParams();
    params = params.append("companyId", `${this.selectedIndex?.id}`);

    this.subscriptions.push(
      this.agencyService.getAgencies(params).subscribe((res) => {
        if (res.success) {
          this.attachedAgencies = res.content.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
              icon: item.picture,
            };
          });

          this.utilService.sortSelectItemByLabel(this.attachedAgencies);
        }
      }),
    );
  }

  prepareEditCompanyForm() {
    this.addCompanyForm = this.formBuilder.group({
      logo: [null, Validators.required],
      name: ["", Validators.required],
      phoneNumber: this.formBuilder.group({
        number: [""],
        countryCode: [""],
      }),
      fax: this.formBuilder.group({
        number: [""],
        countryCode: [""],
      }),
      email: ["", [Validators.required, Validators.email]],
      status: [""],
      address: this.formBuilder.group({
        state: ["", Validators.required],
        city: ["", Validators.required],
        line1: ["", Validators.required],
        line2: [""],
        postalCode: ["", Validators.required],
      }),
    });
  }

  createEditCompanyForm() {
    this.addCompanyForm.patchValue({
      logo: this.selectedIndex.logo,
      name: this.selectedIndex.name,
      phoneNumber: {
        number: this.selectedIndex.phoneNumber.number,
        countryCode: this.selectedIndex.phoneNumber.countryCode,
      },
      fax: {
        number: this.selectedIndex.fax.number,
        countryCode: this.selectedIndex.fax.countryCode,
      },
      email: this.selectedIndex.email,
      status: this.selectedIndex.status,
      address: {
        state: this.selectedIndex.address.state,
        city: this.selectedIndex.address.city,
        line1: this.selectedIndex.address.line1,
        line2: this.selectedIndex.address.line2,
        postalCode: this.selectedIndex.address.postalCode,
      },
    });
  }

  onUpdateClick() {
    let updatedCompany = { ...this.selectedIndex, ...this.addCompanyForm.value };
    this.onSlideBarClose();
    this.confirmationService.confirm({
      message: "Please confirm if you wanted to save all the changes that you've made to this company",
      header: "Confirm Changes?",
      icon: "pi pi-cog",
      acceptLabel: "Update",
      rejectLabel: "Cancel",
      accept: () => {
        this.updateCompany(updatedCompany);
      },
      reject: () => {},
    });
  }

  updateCompany(companyToUpdate: Company) {
    this.subscriptions.push(
      this.companyService.updateCompany(companyToUpdate).subscribe(
        (res) => {
          this.getCompanies();
          this.messageHandlerService.generateToastMessage("success", "", "Company Updated Successfully");
          this.sideNavService.setEDocPurchase();
        },
        (error) => {
          this.messageHandlerService.generateToastMessage("error", "", "Error In Updating Agency");
        },
      ),
    );
  }

  onDropDownChange(index: number, selectedOption) {
    if (selectedOption === "Edit") {
      this.selectedIndex = this.companies[index];
      this.showEditForm = true;
      this.display = true;
      this.prepareEditCompanyForm();
      this.createEditCompanyForm();
    }
    if (selectedOption === "Delete") {
      this.selectedIndex = this.companies[index];
      this.onDeleteClick();
    }
  }

  onDeleteClick() {
    this.onSlideBarClose();
    this.confirmationService.confirm({
      message: "Please confirm if you wanted to delete this Company",
      header: "Delete Company?",
      icon: "pi pi-trash",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      acceptButtonStyleClass: "btn-delete",
      accept: () => {
        this.deleteCompany();
      },
      reject: () => {},
    });
  }

  deleteCompany() {
    if (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin) {
      this.subscriptions.push(
        this.companyService.deleteCompany(this.selectedIndex.id).subscribe(
          (res: any) => {
            if (!res.success && res.returnCode === 405) {
              this.messageHandlerService.generateToastMessage("error", "", `Error in deleting company, ${res.message}`);
            } else {
              this.getCompanies();
              this.messageHandlerService.generateToastMessage("success", "", "Company Deleted Successfully");
            }
          },
          (error) => {
            this.messageHandlerService.generateToastMessage("error", "", "Error In Deleting Company");
          },
        ),
      );
    } else {
      this.messageHandlerService.generateToastMessage("error", "", "Only Admin can delete company");
    }
  }

  changeStatus(index: number) {
    let company: Company = { ...this.companies[index] };
    company.status = company.status === "Active" ? "Inactive" : "Active";
    this.updateCompany(company);
  }

  onAddCompanyClick() {
    this.showEditForm = false;
    this.display = true;
    this.createCompanyForm();
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.pageNo = event.page;
      this.noOfCompaniesPerRow = event.rows;
      this.getCompanies();
    }
  }

  changePage() {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo);
      this.isChangingPage = false;
    });
  }

  onChangeCompanyStatusFilter(event) {
    this.companyStatusFilter = event.value;
    this.pageNo = 0;
    this.getCompanies();
    this.paginator.changePage(this.pageNo);
    this.utilService.setDataInLocalStorage(this.componentLocalStorageKey, "selectedCompanyStatus", this.selectedCompanyStatus);
  }

  onSelectedRowsChange() {
    let companyListingRows = { selectedCompanyListingRows: [] };

    if (this.localStorageService.get("companySettings")) companyListingRows = JSON.parse(this.localStorageService.get("companySettings"));

    companyListingRows.selectedCompanyListingRows = this.selectedRows;
    this.localStorageService.set("companySettings", JSON.stringify(companyListingRows));
  }

  getSelectedRows() {
    if (!this.localStorageService.get("companySettings")) {
      this.selectedRows = defaultSelectedCompanyRows;
      this.localStorageService.set("companySettings", JSON.stringify({ selectedCompanyListingRows: this.selectedRows }));
    } else {
      this.selectedRows = JSON.parse(this.localStorageService.get("companySettings"))?.selectedCompanyListingRows
        ? JSON.parse(this.localStorageService.get("companySettings"))?.selectedCompanyListingRows
        : defaultSelectedCompanyRows;
    }
  }

  isRowVisible(rowName: string) {
    if (this.selectedRows && this.selectedRows.length) return this.selectedRows.find((selectedRow) => selectedRow === rowName);
  }

  onAgencyClick(attachedAgency) {
    this.router.navigate([AGENCY_PATH, attachedAgency]);
  }

  onGenerateReportSelect(event) {
    if (event.value == "Utilization data") {
      this.onExportCompanyUtilizationData();
    } else if (event.value == "Users & Devices") {
      this.onExportIohCompanyUsersInfo();
    }

    this.reportDropdown.updateSelectedOption("");
  }
}
